.bread-crum-wrapper {
    li {
        a{
        font-family: "New Roman Times";
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #54A3C2;
        text-decoration: none;
        }
    }
    li:last-child{
        a{
        color: #0B2E64;
        }
    }
}