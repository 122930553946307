.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.loading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin-top: 20px;

  .spinner-border {
    border-top-color: white;
    border-bottom-color: white;
  }

  pre {
    color: white;
  }
}