@import '../../../assets/styles/sass/Common/mixin';

.UpdatePassword-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 90px;

    .UpdatePassConfirmation-Wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .info-img {
            display: flex;
            align-items: flex-start;
        }

        .acc-creation-txt {
            font-family: "Times New Roman";
            font-size: 24px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #111827;
        }

        .info-img {
            display: flex;
            align-items: flex-start;
        }

        .acc-creation-sub-txt {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #6B7280;
        }
    }

    .signup-img-wrapper {
        position: relative;
        // height: 80vh;

        img {
            width: 50vw;
            height: 80vh;
            object-fit: cover;
        }

        .back-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .landing-page-txt {
            cursor: pointer;
            padding: 10px;
            border-radius: 16px;
            position: absolute;
            left: 15px;
            top: 15px;
            background: #FFFFFFA3;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;

        }
    }

    .signup-form {
        width: 50vw;
        background: #E9ECFF;
        // height: 100vh;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1F2937;

        .signup-form-body {
            background-color: white;
            padding: 20px 50px;
            margin: 0px 40px;
            border-radius: 16px;
            width: 80%;
            // height: 40%;

            .signin_heading {
                font-family: "Times New Roman";
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: black;
                margin-top: 15px;
            }

            .signin_subheading {
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #9CA3AF;
            }

            .form-floating {
                position: relative;

                .eyeicon-img {
                    position: absolute;
                    right: 15px;
                    top: 10px;

                    svg {
                        color: black !important;
                    }
                }

                input {
                    font-size: 16px;
                    font-family: "Times New Roman";
                    min-height: 50px;
                    height: 50px;
                    border-radius: 8px;
                    border: 1px solid #D1D5DB;
                    padding: 0.25rem 0.75rem;
                }

                label {
                    font-family: "Times New Roman";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #9CA3AF;
                }
            }
        }

        .remember_me {
            font-family: "Times New Roman";
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #9CA3AF;
        }

        .terms_span {
            cursor: pointer;
            color: #7DC5DD;
        }

        .signin_btn {
            background-color: #C39D00;
            border-color: #C39D00;
            border-radius: 4px;
            width: 100%;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #FFFFFF;
        }

        .cancel_btn {
            background: #FEF2F2;
            border-color: #FEF2F2;
            border-radius: 4px;
            width: 100%;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #991B1B;
        }
    }

    .no-account-txt {
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #4B5563;
    }

    .login_here {
        text-decoration: underline;
        cursor: pointer;
        color: #C39D00;
        font-weight: 700;
    }

    .forgot-pass {
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #203063;
        position: relative;
        float: right;
        margin-top: -15px;
        margin-bottom: 10px;
        cursor: pointer;
    }
}

@include mq('tablet', max) {
    .UpdatePassword-wrapper {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        .signup-form {
            width: 100vw !important;
            height: 100vh !important;
        }

        .signup-form-body {
            margin: 0px !important;
            padding: 20px !important;
            margin-top: 10px !important;
        }
    }
}