@import '../../../assets/styles/sass/Common/mixin';
.spider-graph-container {
    width: 100% !important;
    canvas {
        height: 100% !important;
        // width: 55% !important;
        margin: auto !important;
    }
}
@include mq('tablet', min){
    .spider-graph-container {
        canvas {
            width: 95% !important;
        }
    }
}

@include mq('tablet', max){
    .spider-graph-container {
        canvas {
            width: 95% !important;
        }
    }
}