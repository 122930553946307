.spider-chart-poc{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    left: 25%;
    height: 50%;
    position: relative;
}
.outer-bar-container {
    position: relative;
    display: inline-block;
    height: 96px;
    width: 96px;
}
  
  .inner-bar-container {    
    height: 67px;
    width: 67px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }