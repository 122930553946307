@import '../../assets/styles/sass/Common/mixin';

.navbar-wrapper {
    height: 90px;
    background: #203063;
    display: flex;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;

    .navbar-logo-img {
        cursor: pointer;
    }

    .wel-img {
        cursor: pointer;
    }

    .nav-items {
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 40px;
        height: 100%;

        .active {
            color: #C39D00 !important;
        }

        .nav-item {
            cursor: pointer;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
        }
    }

    .sign-up-btn {
        border-right: 1px solid #FFFFFF;
        border-radius: 4px;
        background: #C39D00;
        border-color: #C39D00;
        height: 45px;
        width: 120px;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 0px;
        letter-spacing: 0em;
        color: #FFFFFF;
    }

    .login-btns {
        gap: 10px;

        .user-info-wrapper {
            gap: 15px;

            .welcome-txt {
                justify-content: flex-start;
                gap: 5px;

                .wel-txt {
                    font-family: "Times New Roman";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFFFFF;
                }

                .user-name {
                    font-family: "Times New Roman";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFFFFF;

                }
            }
        }
    }

    .sign-in-btn {
        margin-left: -10px;
        border-left: 1px solid #FFFFFF;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 0px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        padding-left: 10px;
        cursor: pointer;
    }

    .divider {
        border-right: 1px solid #FFFFFF;
        height: 30px;
        margin: 0 10px;
    }

    .navbar-logo {
        height: 59px;
        width: 136px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.acc-settings-wrapper {
    .MuiPaper-elevation8 {
        background: #0B2E64CC;
        height: 280px;
        padding: 25px;
        right: 10px;
    }

    ul {
        padding: 0px !important;

        li {
            margin: 10px 0px;
            gap: 10px;
        }
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        gap: 15px !important;
    }

    .settings-txt {
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }
}

.hideHeaders {
    justify-content: space-between;
    padding: 0px 50px;
}

@include mq('tablet', min) {
    .acc-settings-wrapper {
        .MuiPaper-elevation8 {
            top: 92px !important;
        }

        .logout-li {
            margin-top: 40px !important;
            margin-bottom: 0;
        }
    }
}

@include mq('tablet', max) {
    .navbar-wrapper {
        z-index: 99999;
        flex-direction: column;
        height: 115px !important;
        padding-top: 30px !important;
        padding-bottom: 30px !important;
        justify-content: space-around !important;

        .navbar-logo {
            height: 32px !important;
            width: 25% !important;
        }
    }

    .acc-settings-wrapper {
        .MuiPaper-elevation8 {
            background: #0B2E64CC;
            height: 280px;
            padding: 25px;
            top: 120px !important;
            right: 10px;
        }

        .logout-li {
            margin-top: 0px !important;
        }
    }

    .logo-login-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;
        border-bottom: 1px solid white;
    }

    .logo-login-mob-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px 0px 10px;
    }

    .login-btns {
        gap: 10px;
    }

    .sign-up-btn {
        height: 32px !important;
        width: 100px !important;
    }

    .hideHeaders {
        height: 115px !important;
        padding: 0px 10px !important;
    }

    .nav-items {
        margin-bottom: 10px;
        justify-content: space-between !important;
        gap: 20px !important;
        width: 100% !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        border-top: 1px solid #C39D00 !important;
        padding-top: 7px !important;
    }
}