@import '../../../assets/styles/sass/Common/mixin';

.settings-wrapper {
    padding-top: 90px;
    padding-bottom: 100px;
    justify-content: space-between;
    height: 100vh;

    .disabledFormFLoating {
        input {
            color: #9CA3AF !important;
        }
    }

    .input-placeholder {
        &::placeholder {
            color: #9CA3AF;
        }
    }

    .phone-float {
        input {
            width: 100%;
        }
    }

    .signup-img-wrapper {
        position: relative;

        img {
            width: 50vw;
            height: 80vh;
            object-fit: cover;
        }

        .back-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .landing-page-txt {
            cursor: pointer;
            padding: 10px;
            border-radius: 16px;
            position: absolute;
            left: 15px;
            top: 15px;
            background: #FFFFFFA3;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;

        }
    }

    .signup-form {
        height: calc(100vh - 200px);
    }

    .signup-form-body {
        height: 80%;
        overflow-y: auto;
    }

    .signin_heading {
        font-family: "Times New Roman";
        font-size: 24px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #111827;
    }

}

@include mq('tablet', max) {
    // .settings-wrapper{
    //     padding-top: 90px !important;
    //     padding-bottom: 125px !important;
    //     justify-content: space-between;
    // }

}