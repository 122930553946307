@import '../../../assets/styles/sass/Common/mixin';

.page-content {
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
}

.org-type-item {
    // border-bottom: 1px solid #4B5563;
    padding: 10px;
    font-family: "Times New Roman";
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #111827;
    cursor: pointer;
    height: 30px;

}

.org-type-item:hover {
    background-color: #9CA3AF;
}

.org-type-dropdown {
    position: relative;
    z-index: 999;
}

.css-1nmdiq5-menu {
    display: block;
    z-index: 2;
}

.css-13cymwt-control {
    height: 50px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;

    .css-1jqq78o-placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #9CA3AF;
    }

    .css-1dimb5e-singleValue {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .css-qbdosj-Input {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }
}

.css-1jqq78o-placeholder {
    font-family: "Times New Roman";
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #9CA3AF;
}

.css-t3ipsp-control {
    height: 50px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;

    .css-1dimb5e-singleValue {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }
}

.css-16xfy0z-control {
    font-family: "Times New Roman";
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #9CA3AF;
}

.css-olqui2-singleValue {
    font-family: "Times New Roman";
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #9CA3AF;
}

.feedback-wrapper {
    padding-top: 40px;
    padding-bottom: 50px;
    background: #E9ECFF;
}

.dashboard-wrapper {
    padding-right: 100px;
    padding-left: 100px;

    .blue-bg-div {
        background: #E9ECFF;
        height: 600px;
        position: absolute;
        top: -10px;
        width: 100%;
        left: 0px;
        right: 0px;
        z-index: 0;
    }

    .dashboard-survey-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        z-index: 10;

        .survey-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 35%;
            margin-top: 20px;

            .survey-head {
                font-family: "Times New Roman";
                font-size: 22px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: -1px;
                text-align: left;
                color: #C39D00;
            }

            .survey-body {
                font-family: "Times New Roman";
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
            }

            .survey-img {
                height: 520px;
                margin-top: 20px;
            }
        }

        .survey-form {
            box-shadow: 0px 4px 24px 2px rgba(0, 0, 0, 0.1607843137);
            background: white;
            width: 55%;
            padding: 20px;
            border-radius: 16px;
            margin-top: 20px;
            height: auto;
            max-height: 780px;
            overflow-y: auto;

            .survey-form-head {
                font-family: "Times New Roman";
                font-size: 24px;
                font-weight: 300;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #C39D00;
            }

            .survey-form-subhead {
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #9CA3AF;
            }
        }
    }

}

@include mq('tablet', max) {
    .page-content {
        margin-bottom: 155px !important;

        .dashboard-wrapper{
            padding-right: 10px !important;
            padding-left: 10px !important;

            .dashboard-survey-wrapper{
                flex-direction: column !important;
                width: 100%;
                .survey-content{
                    width: 100% !important;
                    margin-top: 26px !important;
                }
                .survey-form{
                    width: 100% !important;
                }
            }
            .contact-us-wrapper{
                width: 90% !important;
            }
        }
        .contact-us-wrapper{
            width: 90% !important;
        }
    }
}