@import '../../../assets/styles/sass/Common/mixin';

.admin-users-wrapper {
    margin-top: 20px;

    .all-date-wrapper {
        display: flex;
        margin-top: 10px;
        position: absolute;
    }

    .progress-val {
        .progress-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
        }

        .progress-txt {
            font-family: "Times New Roman";
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #666666;
        }
    }

    .no-found-txt {
        font-family: "Times New Roman";
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -1px;
        text-align: center;
        color: #0B2E64;
        margin-bottom: 20px;
    }

    .highlightedAllDate {
        cursor: pointer;
        width: 100px;
        background: #73C5DC !important;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        // color: #FFFFFF !important;
        padding: 10px;
        border-radius: 0px 4px 4px 0px;
    }

    .highlightedDelDate {
        cursor: pointer;
        width: 80px;
        background: #73C5DC !important;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF !important;
        padding: 10px;
        border-radius: 4px 0px 0px 4px;
    }

    .sel-date {
        cursor: pointer;
        font-family: "Times New Roman";
        background-color: #FFFFFF !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
        border: 1px solid #D1D5DB;
        padding: 10px;
        border-radius: 4px 0px 0px 4px;
        width: 80px;
    }

    .all-date {
        cursor: pointer;
        background-color: #FFFFFF !important;
        font-family: "Times New Roman";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
        color: black;
        border: 1px solid #D1D5DB;
        padding: 10px;
        border-radius: 0px 4px 4px 0px;
        width: 100px;
    }

    .admin-users-heading {
        font-family: "Times New Roman";
        font-size: 24px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: -1px;
        text-align: left;
        color: #C39D00
    }

    .survey-date-filters {
        display: flex;
        margin-top: 20px;

        img {
            cursor: pointer;
        }
    }

    .survey-divider {
        border: 5px solid #C39D00;
        width: 60px;
        border-radius: 16px;
        margin-top: 10px;
    }

    .progress {
        height: 10px;
        background: #0B2E64;
        border-color: #0B2E64;
        width: 100%;
    }

    .progress-bar {
        background: #73C5DC;
        border-color: #73C5DC;
    }

    .action-btn {
        background: #C39D00;
        border-color: #C39D00;
        border-radius: 100px;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: white;
    }

    .admin-users-body {
        margin-bottom: 20px;
    }

    .leagues-headers {
        justify-content: space-between;
        display: flex;
        gap: 10px;
        margin: 0px 10px;
    }

    .add-league {
        .add-league-btn {
            border-radius: 10px;
            background: #88BD36;
            border-color: #88BD36;
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: -0.40799999237060547px;
            color: #FFFFFF;
        }
    }

    .width15 {
        width: 15%;
    }

    .table-cls {
        .app-users-table-head {
            th:first-child {
                display: flex;
                // gap: 10px;

                div {
                    display: flex;
                    gap: 16px;
                }
            }

            th {
                padding: 10px 0px 10px 10px;
                font-family: "Times New Roman";
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #0B2E64;
                background: #E9ECFF;


                span {
                    margin-left: 10px;
                }
            }
        }
    }

    .pagination-body {
        gap: 10px;

        .paginationButton {
            border: none;
            height: 30px;
            width: 30px;
            background: #F1F2F2;
            border-radius: 5px;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
        }

        .activeButton {
            background: #88BD36;
            border: none;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #FFFFFF;
        }

        .previcon {
            height: 30px;
            width: 30px;
            cursor: pointer;
        }
    }

    .table-body-row {
        height: 58px;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.006em;
        text-align: left;

        td {
            padding: 10px;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #0B2E64;
        }
    }

    .search-bar-leagues {
        input {
            // height: 36px;
            padding: 7px 12px 7px 12px;
            border-radius: 8px;
            border: 1px solid #DDE2E4;
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.40799999237060547px;
            text-align: left;

        }
    }

    .search-icon {
        svg {
            margin-top: -4px;
            margin-left: -30px;
        }
    }

    /* Style for even rows */
    tr:nth-child(even) {
        border: 1px solid #D1D5DB;
    }

    /* Style for odd rows */
    tr:nth-child(odd) {
        border: 1px solid #D1D5DB;
    }

    .app-users-dropdown {
        gap: 16px;
    }

    .gender-dropdown {
        .css-1u9des2-indicatorSeparator {
            display: none !important;
        }

        .css-13cymwt-control {
            border-radius: 6px;
            border: 1px solid #DDE2E4
        }

        svg {
            filter: invert(67%) sepia(10%) saturate(2865%) hue-rotate(42deg) brightness(180%) contrast(79%);
        }

        .css-qbdosj-Input {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.006em;
            text-align: left;
            color: #414042;
        }
    }

    .app-users-filters {
        gap: 10px;
        height: 36px;
    }

    .uname-cell {
        display: flex;
        gap: 16px;
    }
}


@include mq('tablet', max) {
    .admin-users-wrapper {
        .admin-users-body {
            .table-container {
                max-width: 100%;
                overflow-x: auto;
                ;
            }
        }
    }
}